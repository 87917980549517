import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import SolutionSliderEachItem from "../SolutionSliderEachItem/SolutionSliderEachItem";

import "./SolutionSlider.css"

// Learning From Cryptopedia Slider

const SwiperCarousel2 = () => {
  return (
    <div className="LandingpageCrypSlider">
      <Swiper
        className=" CryptopediaSider"
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={1}
        centeredSlides={true} // Center the active slide
        initialSlide={1} // Set the second slide (index 1) as active by default
        navigation
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 1.4, // Number of slides to show on mobile
            spaceBetween: 10, // Space between slides on mobile
          },
          767: {
            slidesPerView: 1, // Number of slides to show on mobile
            spaceBetween: 15, // Space between slides on mobile
          },
        }}
        pagination={{
          spaceBetween: 70,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/Iot"
            Src={"/Images/SolutionSliderImage1.png"}

            text="Internet of Things <br><br> <span>Experience the freedom to connect, monitor, and control your devices in even the most challenging environments. </span>"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/satellites"
            Src={"/Images/SolutionsliderSatellite.png"}

            text="Satellite Communications <br><br> <span>Experience global coverage with our extensive satellite network, reaching even the most remote corners of the world.</span>"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/cybersecurity"
            Src={"/Images/SolutionsliderCybersecurity.png"}
            text="Cybersecurity<br><br> <span>Prevent cyber threats with dynamic, scalable solutions. Leveraging best-of-breed components, we keep you safe.</span>"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/networks"
            Src={"/Images/SolutionsliderNetworking.png"}

            text="Networking Solutions<br><br> <span>Gain full visibility and control over your network operations with our advanced OSI L-2 & L-3 solutions.</span>"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/surveillance"
            Src={"/Images/SolutionsliderServeillance.png"}

            text="Surveillance<br><br> <span>Enhance your security infrastructure with our comprehensive surveillance solutions.</span>"
          />
          <style>
            {`.swiper-pagination{
      color: red;
    },
    .swiper-pagination-bullet {
      color: red;
      background-color: red;
    }
    `}
          </style>
        </SwiperSlide>

        {/* <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/powersolutions"
            Src={"/Images/SolutionsliderPower.png"}

            text="Power Solutions"
          />
        </SwiperSlide>

        <SwiperSlide>
          <SolutionSliderEachItem
            RouterLink="/professionalservices"
            Src={"/Images/SolutionsliderProfessional.png"}

            text="Professional Services"
          />
          
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default function SolutionSlider() {
  return (
    <div className="LearningFromCryptopedia">
      {/* ************************************************************
       ******* Learning from Cryptopedia Heading SECTION ******
       *****************************************************************/}

      
      <SwiperCarousel2 />
    </div>
  );
}
