import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Text from "../../Components/Text/Text";
import NewLandingNewsSlider from "../../Components/NewLandingNewsSlider/NewLandingNewsSlider";
import MyButton from "../../Components/Button/Button";
import SolutionSlider from "../../Components/SolutionSlider/SolutionSlider";
import { HashLink } from "react-router-hash-link";
import ScrollHorizontalLine from "../../Components/ScrollHorizontalLine/ScrollHorizontalLine";
import ImageComp from "../../Components/ImageComp/ImageComp";
import "../../Styles/Landing.css"
export default function NewLanding() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Set the default playback speed to 2x
      videoRef.current.defaultPlaybackRate = 0.6;
      // Start the video playback
      videoRef.current.play();

      // Optionally, change the playback speed to 3x after the video starts
      videoRef.current.playbackRate = 0.5;
    }
  }, []);

  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    const videoElement = document.getElementById("headerVideo");

    const handleVideoReady = () => {
      setVideoReady(true);
    };

    if (videoElement) {
      videoElement.addEventListener("canplaythrough", handleVideoReady);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("canplaythrough", handleVideoReady);
      }
    };
  }, []);

  return (
    <div className="NewLanding">
      <Helmet>
        <title>DynaSys Networks - Ideate. Innovate. Implement</title>
      </Helmet>
      {!videoReady && (
        <div id="preloader">
          <div class="preloaderbor">
            <div class="spinner"></div>
            <div id="preloader-inner" class="preloadercontent">
              <ImageComp Src="DynaSysLoadermdpi.png" Width="60%" />
            </div>
          </div>
        </div>
      )}
      <ScrollHorizontalLine />
      <div className="NewLandingHero">
        {/* Landing page header section */}
        <div className="headerSecVideo">
          <video
            ref={videoRef}
            id="headerVideo"
            width="320"
            height="240"
            playsInline
            loop
            autoPlay
            muted
            preload="auto"
          >
            <source src="Images/dynaSys_animationn.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="HeroContent">
          <Text
            text={"A Realm of <span> Boundless <br> Opportunities</span>"}
            FontSize={"8rem"}
            FontWeight={"600"}
            Color={"white"}
            LineHeight={"1"}
          />
          <Text
            text={"Let’s come together and discover more..."}
            Color={"#D9D9D9"}
            Margin={"4rem 0rem 0rem 0rem"}
            FontSize={"3.6rem"}
          />
          <div className="HeroSectionButton">
            <HashLink smooth to="/#SolutionSlider">
              <MyButton
                text="Our Solutions"
                Border="1px solid white"
                Radius="12px"
                FontWeight="400"
                FontSize="2rem"
                Padding="8px 18px"
                Color={"white"}
              />
            </HashLink>

            <MyButton
              RouteLink="/contactus"
              text="Contact Us"
              Border="1px solid white"
              Radius="12px"
              FontWeight="400"
              FontSize="2rem"
              Padding="8px 18px"
              Margin={"0rem 0rem 0rem 2rem"}
              Color={"white"}
            />
          </div>
        </div>
      </div>

      {/* Discover DynaSys Network section */}
      <div className="DiscoverDynasysSection">
        <Text
          text={"Discover DynaSys Network"}
          FontWeight={"600"}
          Margin={"12rem 0rem 2rem 0rem"}
        />
        <Text
          FontSize={"5rem"}
          Margin={"2rem 0rem 12rem 0rem"}
          text={
            "At DynaSys Networks, we are committed to delivering innovative technology solutions designed to meet the unique needs of our diverse customer base."
          }
          FontWeight={"600"}
        />
      </div>

      {/* NEWS SLIDER */}
      <div className="NewNewsSlider">
        <NewLandingNewsSlider />
      </div>

      {/* WHO WE ARE SECTION */}
      <div className="WhoWeAre">
        <div className="WhoWeAreLeft">
          <div className="WhoWeAreImage">
            <Text
              text={"Who We Are"}
              FontWeight={"600"}
              FontSize={"3.8rem"}
              Color={"white"}
            />
            <ImageComp
              Src={"/Images/newLanding/whoweareBg.png"}
              Margin={"4rem 0rem 0rem 0rem"}
              Width={"100%"}
            />
          </div>
          <div className="WhoWeAreText">
            <Text
              FontWeight={"500"}
              FontSize={"2.5rem"}
              Color={"white"}
              text={
                "DynaSys Networks is a technology company founded in March 2021. The company has two distinct business units: Product Development and Systems Integration.<br><br>In Product Development, our focus areas include Internet of Things and Space. Within Systems Integration, we specialize in cybersecurity, satellite communications, networking solutions, surveillance and security solutions, and power solutions."
              }
            />
          </div>

          <div className="WhoWeAreButton">
            <MyButton
              RouteLink="/whoweare"
              text="Who We Are"
              Border="1px solid #808080"
              Radius="8px"
              FontWeight="400"
              FontSize="1.5rem"
              Padding="8px 18px"
              Color={"white"}
            />
            <MyButton
              RouteLink="/leadership"
              text="Our Leadership"
              Border="1px solid #808080"
              Radius="8px"
              FontWeight="400"
              FontSize="1.5rem"
              Padding="8px 18px"
              Margin={"0rem 0rem 0rem 2rem"}
              Color={"white"}
            />
          </div>
        </div>
        <div className="WhoWeAreRight">
          <ImageComp
            Src={"/Images/newLanding/whoweareAlifBg.png"}
            Margin={"0rem 0rem 2rem 0rem"}
            Width={"100%"}
          />
          <ImageComp
            Src={"/Images/newLanding/Frame.png"}
            Margin={"0rem 0rem 2rem 0rem"}
            Padding={"0rem 0rem 0rem 6rem"}
          />
          <div className="WhoWeAreAlifText">
          <Text
            Margin={"2rem 0rem 0rem 0rem"}
            FontWeight={"500"}
            FontSize={"2.5rem"}
            text={
              "ALEF serves as a comprehensive ecosystem that revolutionizes the way organizations approach IoT.<br><br>Designed to simplify your journey from data collection to insightful action, ALEF offers an unparalleled user experience coupled with robust functionalities."
            }
          />
          </div>
          <div className="WhoWeAreButton">
            <MyButton
              RouteLink="/whoweare"
              text="Who We Are"
              Border="1px solid #808080"
              Radius="8px"
              FontWeight="700"
              FontSize="1.5rem"
              Padding="8px 18px"
            />
            <MyButton
              RouteLink="/leadership"
              text="Our Leadership"
              Border="1px solid #808080"
              Radius="8px"
              FontWeight="700"
              FontSize="1.5rem"
              Padding="8px 18px"
              Margin={"0rem 0rem 0rem 2rem"}
            />
          </div>
        </div>
      </div>

      {/* Solution Slider */}
      <div className="MediaContentHeading">
        <Text
          text="Our Solutions"
          FontSize="2.5rem"
          FontWeight="500"
          Margin="50px 0px 35px 0px"
        />
      </div>
      <div className="LandingSolutionSlider" id="SolutionSlider">
        <SolutionSlider />
      </div>

      {/* Verticals Section */}
      <div className="VerticalsSecHead" id="Verticals">
        <Text
          text="Verticals"
          FontSize="2.5rem"
          FontWeight="500"
          Margin="50px 0px 35px 0px"
        />
      </div>
      <div className="VerticalsSec">
        <div className="verticaleactItem">
          <ImageComp Src="/Images/Telecoms.webp" Width="100%" />
          <Text text="Telecoms" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Defence.webp" Width="100%" />
          <Text text="Defence" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Enterprise.webp" Width="100%" />
          <Text text="Enterprise" Margin="15px 0px 15px 0px" />
        </div>

        <div className="verticaleactItem">
          <ImageComp Src="/Images/Government.webp" Width="100%" />
          <Text text="Government" Margin="15px 0px 15px 0px" />
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
    </div>
  );
}
