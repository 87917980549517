import React, { useEffect, useState } from "react";
import MyButton from "../Button/Button";
import "./Navbar.css";
import { HashLink } from "react-router-hash-link";
import SolutionDropdown from "../SolutionDropdown/SolutionDropdown";
import { useLocation } from "react-router-dom"; // Ensure you have react-router-dom installed

export default function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    setIsDropdownOpen(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
  };

  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

  const handleDropdownToggle2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
    setIsDropdownOpen(false);
    setIsDropdownOpen3(false);
  };

  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);

  const handleDropdownToggle3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
    setIsDropdownOpen(false);
    setIsDropdownOpen2(false);
  };

  const [bgColor, setBgColor] = useState("white");
  const [FontColor, setFontColor] = useState("black");
  const [borderBottom, setBorderBottom] = useState("1px solid #B5B5B5");


  const location = useLocation(); // useLocation hook to get current path

  useEffect(() => {
    // Whenever location changes, this effect will run
    if (location.pathname === "/") {
      setBgColor("#00000072");
      setFontColor("white");
      setBorderBottom("none"); // Remove border on homepage

    } else {
      setBgColor("white");
      setFontColor("black");
      setBorderBottom("1px solid #B5B5B5"); // Set border on other pages

    }
  }, [location.pathname]); // Re-run effect whenever the path changes

  // Determine the source based on the current path
  const LogoSrc =
    location.pathname === "/"
      ? "/Images/whiteLogo.svg"
      : "/Images/SiteLogo.svg";

  return (
    <div className="Navbar" style={{ backgroundColor: bgColor, borderBottom: borderBottom, }}>
      <div className="SiteLogo">
        <MyButton
          RouteLink="/"
          Src={LogoSrc}
          Width={"100%"}
          ImgWidth={"100%"}
        />
      </div>
      <div className="NavbarItems">
        <div className="items">
          <ul>
            <li className="AboutItem">
              <MyButton
                text="About Us"
                FontSize="2rem"
                FontWeight="600"
                Color={FontColor}
              />
              <div className={`aboutDropdown ${isDropdownOpen ? "open" : ""}`}>
                <ul>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>

                      <MyButton
                        RouteLink="/leadership"
                        text="Our Leadership"
                        FontSize="1.6rem"
                        FontWeight="600"
                        Color="#797979"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/whoweare"
                        text="Who We Are"
                        FontSize="1.6rem"
                        FontWeight="600"
                        Color="#797979"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                      >
                        {/* <path
                          d="M4.9847 7.96777L0.668381 0.491694L9.30101 0.491694L4.9847 7.96777Z"
                          fill="#4F4F4F"
                        /> */}
                      </svg>
                    </div>
                    {/* <div className="LeaderShip_Sub_Menu">
                      <ul>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 384 512"
                          >
                            <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                          </svg>
                          <MyButton
                            RouteLink="/founder&ceo"
                            text="Founder & CEO"
                            FontSize="1.6rem"
                            FontWeight="600"
                            Color="#797979"
                            Margin="0px 0px 0px 15px"
                          />
                        </li>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 384 512"
                          >
                            <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                          </svg>
                          <MyButton
                            RouteLink="/board_chairman"
                            text="Board Chairman"
                            FontSize="1.6rem"
                            FontWeight="600"
                            Color="#797979"
                            Margin="0px 0px 0px 15px"
                          />
                        </li>
                      </ul>
                    </div> */}
                  </li>
                  {/* <li>
                    <ImageComp Src="/Images/aboutitemimg.png" />
                  </li> */}
                </ul>
              </div>
            </li>
            <li className="SolutionItem">
              <HashLink smooth to="/#SolutionSlider" onClick={toggleMobileMenu}>
                <MyButton text="Solutions" FontWeight="600" Color={FontColor} />
              </HashLink>
              <div className="SolutionDropdown">
                <SolutionDropdown
                  RouterLink="/Iot"
                  Src="/Images/Iot.webp"
                  text="Internet of Things"
                />
                <SolutionDropdown
                  RouterLink="/satellites"
                  Src="/Images/Communication.webp"
                  text="Satellite Communications"
                />
                <SolutionDropdown
                  RouterLink="/cybersecurity"
                  Src="/Images/cyberSecurity.webp"
                  text="Cybersecurity"
                />
                <SolutionDropdown
                  RouterLink="/networks"
                  Src="/Images/networks.webp"
                  text="Networking Solutions"
                />
                <SolutionDropdown
                  RouterLink="/surveillance"
                  Src="/Images/Surveillance.png"
                  text="Surveillance"
                />
                <SolutionDropdown
                  RouterLink="/powersolutions"
                  Src="/Images/PowerSolutions.png"
                  text="Power Solutions"
                />
                <SolutionDropdown
                  RouterLink="/professionalservices"
                  Src="/Images/ProfessionalServices.png"
                  text="Professional Services"
                />
              </div>
            </li>
            <li>
              <HashLink smooth to="/#Verticals" onClick={toggleMobileMenu}>
                <MyButton text="Verticals" FontWeight="600" Color={FontColor} />
              </HashLink>
            </li>
            <li className="AboutItem">
              <MyButton
                text="Media Center"
                FontSize="2rem"
                FontWeight="600"
                Color={FontColor}
              />
              <div className={`aboutDropdown ${isDropdownOpen ? "open" : ""}`}>
                <ul>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/press_releases"
                        text="Press Releases"
                        FontSize="1.6rem"
                        FontWeight="600"
                        Color="#797979"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <MyButton
                RouteLink="/careers"
                text="Careers"
                FontWeight="600"
                Color={FontColor}
              />
            </li>
            <li>
              <MyButton
                RouteLink="/contactus"
                text="Contact Us"
                FontWeight="600"
                Color={FontColor}
              />
            </li>
            {/* <li>
              <MyButton RouteLink="/mobmenu" text="mob" FontWeight="600" />
            </li> */}
          </ul>
        </div>
      </div>

      {/* Mobile Menu */}

      <div className="mobileMenu">
        <input
          type="checkbox"
          id="navi-toggle"
          className="checkbox"
          checked={isMobileMenuOpen}
        />
        <label
          htmlFor="navi-toggle"
          className="button"
          onClick={toggleMobileMenu}
        >
          <span className="icon"></span>
        </label>
        <div className="background"></div>
        <nav className={`nav ${isMobileMenuOpen ? "active" : ""}`}>
          <ul className="list">
            <li className="item">
              <button onClick={handleDropdownToggle}>
                <MyButton
                  text="About Us"
                  FontSize="3.5rem"
                  FontWeight="600"
                  Color="white"
                  Margin="0px 50px 0px 0px"
                />
              </button>

              {isDropdownOpen && (
                <div className="MobaboutDropdown open">
                  <ul>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>
                        <MyButton
                          RouteLink="/leadership"
                          text="Our Leadership"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>
                        <MyButton
                          RouteLink="/whoweare"
                          text="Who We Are"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li className="item">
              <button onClick={handleDropdownToggle2}>
                <MyButton
                  text="Solutions"
                  Margin="0px 50px 0px 0px"
                  FontSize="3.5rem"
                  FontWeight="600"
                  Color="white"
                />
              </button>
              {isDropdownOpen2 && (
                <div className="MobaboutDropdown open">
                  <ul>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>
                        <MyButton
                          RouteLink="/Iot"
                          text="Internet of Things"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>

                        <MyButton
                          RouteLink="/satellites"
                          text="Satellite Communications"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>

                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>

                        <MyButton
                          RouteLink="/cybersecurity"
                          text="Cybersecurity"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>

                        <MyButton
                          RouteLink="/networks"
                          text="Networking Solutions"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>

                        <MyButton
                          RouteLink="/surveillance"
                          text="Surveillance"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>

                        <MyButton
                          RouteLink="/powersolutions"
                          text="Power Solutions"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>

                        <MyButton
                          RouteLink="/professionalservices"
                          text="Professional Services"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li className="item">
              <HashLink smooth to="/#Verticals" onClick={toggleMobileMenu}>
                <MyButton
                  text="Verticals"
                  Margin="0px 50px 0px 0px"
                  FontSize="3.5rem"
                  FontWeight="600"
                  Color="white"
                />
              </HashLink>
            </li>

            {/* Media center tab for mobile devices */}

            <li className="item">
              <button onClick={handleDropdownToggle3}>
                <MyButton
                  text="Media Center"
                  FontSize="3.5rem"
                  FontWeight="600"
                  Color="white"
                  Margin="0px 50px 0px 0px"
                />
              </button>

              {isDropdownOpen3 && (
                <div className="MobaboutDropdown open">
                  <ul>
                    <li>
                      <div className="aboutItemdropcontent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                        </svg>
                        <MyButton
                          RouteLink="/press_releases"
                          text="Press Releases"
                          FontSize="2rem"
                          FontWeight="600"
                          Color="#797979"
                          handleClick={toggleMobileMenu}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </li>

            <li className="item">
              <MyButton
                RouteLink="/careers"
                text="Careers"
                FontSize="3.5rem"
                FontWeight="600"
                Margin="0px 50px 0px 0px"
                Color="white"
                handleClick={toggleMobileMenu}
              />
            </li>
            <li className="item">
              <MyButton
                RouteLink="/contactus"
                text="Contact Us"
                FontSize="3.5rem"
                FontWeight="600"
                Margin="0px 50px 0px 0px"
                Color="white"
                handleClick={toggleMobileMenu}
              />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
